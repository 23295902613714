import React from "react"
import PropTypes from "prop-types"
import { BackdropWrapper } from "./Backdrop.styles"

const Backdrop = ({ show, handleClose }) =>
  show ? <BackdropWrapper onClick={handleClose} /> : null

Backdrop.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default Backdrop

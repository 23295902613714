import PropTypes from "prop-types"
import React from "react"
import { NavbarWrapper } from "./Navbar.styles"

import barsIcon from "../../assets/icons/bars.png"
// import calendarIcon from "../../assets/icons/calendar.png"

const Navbar = ({ showShadow, handleOpen }) => {
  return (
    <NavbarWrapper shadow={showShadow}>
      <button
        aria-label="menu"
        title="menu"
        className="toggle"
        onClick={handleOpen}
      >
        <img src={barsIcon} alt="nawigacja" />
      </button>
      {/* <button className="calendarium">
        <span>Calendarium</span>
        <img src={calendarIcon} alt="kalendarz" />
      </button> */}
    </NavbarWrapper>
  )
}

Navbar.propTypes = {
  showShadow: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
}

export default Navbar

export const colors = {
  mainBlack: `#151615`,
  mainWhite: `#fff`,
  gray: `#888`,
  lightGray: `#F7F7F7`,
  darkGray: `#555`,
}

export const fonts = {
  fontPrimary: `'Montserrat', sans-serif`,
  fontSecondary: `'Raleway', sans-serif`,
  fontLight: `300`,
  fontRegular: `500`,
  fontBold: `700`,
}

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
}

export const limiter = `
  padding: 0 1.05em;

  @media ${device.mobileL} {
    padding: 0 45px;
    margin: 0 auto;
  }

  @media ${device.tablet} {
    padding: 0 70px;
  }

  @media ${device.laptop} {
    padding: 0 70px;
    max-width: 1340px;
  }

  @media ${device.laptopL} {
    padding: 0;
    max-width: 1200px;
  }
`

export const paddingTop = `
  padding-top: 70px;
  position: relative;

  @media ${device.mobileL} {
    padding-top: 70px;
  }

  @media ${device.tablet} {
    padding-top: 100px;
  }
`

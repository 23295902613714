import React from "react"
import PropTypes from "prop-types"
import {
  Drawer,
  Logo,
  Line,
  CloseButton,
  LinkList,
  LinkItem,
  NavLink,
  SocialTitle,
  SocialList,
  SocialItem,
  SocialLink,
} from "./SideDrawer.styles"
import logo from "../../assets/dzikistyl-company-logo-light.png"
import close from "../../assets/icons/close.png"

const SideDrawer = ({ show, handleClose }) => (
  <Drawer show={show}>
    <CloseButton onClick={handleClose}>
      <img src={close} alt="Zamknij menu" />
    </CloseButton>
    <Logo src={logo} alt="Dzikistyl Company" />
    <Line />
    <LinkList>
      <LinkItem>
        <NavLink onClick={handleClose} to="/">
          Home
        </NavLink>
      </LinkItem>
      <LinkItem>
        <NavLink onClick={handleClose} to="/spektakle">
          Projekty autorskie
        </NavLink>
      </LinkItem>
      <LinkItem>
        <NavLink onClick={handleClose} to="/wspolpraca">
          Współpraca choreograficzna
        </NavLink>
      </LinkItem>
      <LinkItem>
        <NavLink onClick={handleClose} to="/edukacja">
          Edukacja
        </NavLink>
      </LinkItem>
      <LinkItem>
        <NavLink onClick={handleClose} to="/pokazy">
          Pokazy dla firm
        </NavLink>
      </LinkItem>
      <LinkItem>
        <NavLink onClick={handleClose} to="/kontakt">
          Kontakt
        </NavLink>
      </LinkItem>
    </LinkList>
    <Line />
    <SocialTitle>Social media</SocialTitle>
    <SocialList>
      <SocialItem>
        <SocialLink
          href="https://pl-pl.facebook.com/DzikiStyl"
          title="Profil na Facebooku"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </SocialLink>
      </SocialItem>
      <SocialItem>
        <SocialLink
          href="https://www.instagram.com/dzikistyl_company/"
          title="Profil na Instagramie"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </SocialLink>
      </SocialItem>
      <SocialItem>
        <SocialLink
          href="https://www.youtube.com/user/dzikistylcrew"
          title="Kanał na Youtube"
          target="_blank"
          rel="noopener noreferrer"
        >
          Youtube
        </SocialLink>
      </SocialItem>
    </SocialList>
  </Drawer>
)

SideDrawer.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default SideDrawer

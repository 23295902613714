import styled from "styled-components"
import { colors, fonts, device } from "../../utils/styles"
import { Link } from "gatsby"

export const Drawer = styled.nav`
  position: fixed;
  overflow-y: scroll;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  max-width: 900px;
  background: ${colors.mainBlack};
  padding: 1em;
  padding-top: 2em;
  padding-bottom: 2em;
  transition: transform 0.43s ease-in-out;
  transform: ${props => (props.show ? "translateX(0)" : "translateX(-100%)")};

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    padding: 3em;
  }
`

export const Logo = styled.img`
  width: 200px;

  @media ${device.tablet} {
    width: 300px;
  }
`

export const Line = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background: ${colors.gray};
  margin: 1.4em 0;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  opacity: 0.5;
  transition: opacity 0.35s ease-in-out;
  cursor: pointer;

  @media ${device.tablet} {
    top: 40px;
    right: 40px;
  }

  &:hover {
    opacity: 1;
  }
`

export const NavLink = styled(Link)`
  color: ${colors.mainWhite};
  font-size: 1rem;
  font-weight: ${fonts.fontBold};
  line-height: 1.7rem;
  text-transform: uppercase;
  transition: color 0.35s ease-in-out;

  @media ${device.tablet} {
    font-size: 2.4rem;
    line-height: 3.3rem;
  }
`

export const LinkList = styled.ul`
  &:hover ${NavLink} {
    color: ${colors.darkGray};
  }

  &:hover ${NavLink}:hover {
    color: ${colors.mainWhite};
  }
`

export const LinkItem = styled.li``

export const SocialTitle = styled.p`
  color: ${colors.gray};
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin-bottom: 0.4em;
  font-weight: ${fonts.fontBold};
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 1.6rem;
  }
`

export const SocialList = styled.ul``

export const SocialItem = styled.li``

export const SocialLink = styled.a`
  color: ${colors.gray};
  font-size: 1.1rem;
  line-height: 1.65rem;
  font-weight: ${fonts.fontRegular};
  transition: color 0.35s ease-in-out;

  &:hover {
    color: ${colors.mainWhite};
  }

  @media ${device.tablet} {
    font-size: 1.3em;
    line-height: 2rem;
  }
`

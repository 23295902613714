module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DzikiStyl Company","short_name":"DSC","start_url":"/","background_color":"#ffffff","theme_color":"#151615","display":"standalone","description":"DzikiStyl Company - Trójmiejski Teatr Tańca","icon":"src/assets/dzikistyl-company-icon-short.png","icons":[{"src":"/favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/icon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"/favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b969a4c6c068c0cf2f881e1269b0c2cd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

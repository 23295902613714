import React from "react"
import PropTypes from "prop-types"
import {
  FooterWrapper,
  FooterLimiter,
  Top,
  ImageWrapper,
  Image,
  Info,
  ListWrapper,
  ListTitle,
  List,
  ListItem,
  NavLink,
  Bottom,
  Copyright,
} from "./Footer.styles"
import logo from "../../assets/dzikistyl-company-logo-light.png"

const Footer = ({ show }) => (
  <FooterWrapper show={show}>
    <FooterLimiter>
      <Top>
        <ImageWrapper>
          <Image src={logo} alt="Logo Dzikistyl Company" />
        </ImageWrapper>
        <Info>
          <ListWrapper>
            <ListTitle>Social media</ListTitle>
            <List>
              <ListItem>
                <NavLink
                  href="https://pl-pl.facebook.com/DzikiStyl"
                  title="Profil na Facebooku"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  href="https://www.instagram.com/dzikistyl_company/"
                  title="Profil na Instagramie"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  href="https://www.youtube.com/user/dzikistylcrew"
                  title="Kanał na Youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Youtube
                </NavLink>
              </ListItem>
            </List>
          </ListWrapper>
          <ListWrapper>
            <ListTitle>Kontakt</ListTitle>
            <List>
              <ListItem>dzikistylcompany@wp.pl</ListItem>
              <ListItem>Tel: 506 999 585</ListItem>
            </List>
          </ListWrapper>
        </Info>
      </Top>
      <Bottom>
        <Copyright>© {new Date().getFullYear()}, DzikiStyl Company</Copyright>
      </Bottom>
    </FooterLimiter>
  </FooterWrapper>
)

Footer.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default Footer

import styled from "styled-components"
import { colors, device, fonts } from "../../utils/styles"

export const FooterWrapper = styled.footer`
  width: 100%;
  /* position: fixed; */
  /* bottom: 0; */
  /* z-index: -1; */
  background: ${colors.mainBlack};
  color: ${colors.mainWhite};

  @media ${device.tablet} and (max-width: 1920px) {
    transition: transform 0.43s ease-in-out;
    transform: ${props => (props.show ? "translateX(800px)" : "translateX(0)")};
  }

  @media (min-width: 1921px) {
    max-width: 1916px;
    margin: 0 auto;
    margin-left: 2px;
  }
`

export const FooterLimiter = styled.div`
  padding: 0 0.7em;

  @media ${device.mobileL} {
    margin: 0 auto;
  }

  @media ${device.tablet} {
    padding: 0 70px;
  }

  @media ${device.laptop} {
    padding: 0 70px;
    max-width: 1340px;
  }

  @media ${device.laptopL} {
    padding: 0;
    max-width: 1200px;
  }
`

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    padding-top: 2.5em;
  }
`

export const ImageWrapper = styled.figure`
  @media ${device.tablet} {
    flex: 0.4;
  }
`

export const Image = styled.img``

export const Info = styled.div`
  width: 100%;

  @media ${device.mobileL} {
    flex: 0.6;
    display: flex;
    justify-content: space-around;
  }

  @media ${device.laptop} {
    padding-left: 7.5em;
  }

  @media ${device.laptopL} {
    padding-left: 12em;
  }
`

export const ListWrapper = styled.div`
  padding: 0 1.1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobileL} {
    border-left: 0.5px solid ${colors.gray};
    padding: 0 0 0 0.6em;
    display: block;
    text-align: start;
  }

  @media ${device.tablet} {
    flex: 0.5;
  }
`

export const ListTitle = styled.p`
  font-weight: ${fonts.fontRegular};
  font-size: 1.4rem;
`

export const List = styled.ul`
  margin-top: 0.35em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.6rem;

  &:first-of-type {
    margin-bottom: 1.15em;
  }

  @media ${device.mobileL} {
    &:first-of-type {
      margin-bottom: 0;
    }
  }
`

export const NavLink = styled.a`
  color: ${colors.gray};
`

export const ListItem = styled.li`
  color: ${colors.gray};
  font-weight: ${fonts.fontBold};
  font-size: 1.05rem;
  line-height: 1.9rem;
  width: 100%;

  @media ${device.mobileL} {
    width: auto;
  }

  @media ${device.tablet} {
    font-size: 0.95rem;
    line-height: 1.75rem;
  }

  @media ${device.laptop} {
    font-size: 0.9rem;
    line-height: 1.8rem;
  }

  @media ${device.laptop} {
    font-size: 1.05rem;
    line-height: 2rem;
  }
`

export const Bottom = styled.div`
  padding: 2em 0;

  text-align: center;

  @media ${device.mobileL} {
    padding-top: 3em;
  }

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
  }
`

export const Copyright = styled.p`
  color: ${colors.gray};
  line-height: 1.7rem;
  font-size: 0.9rem;
`

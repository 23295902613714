import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Transition from "../../utils/transition"

import { GlobalStyle, Main } from "./layout.styles"

import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"
import SideDrawer from "../SideDrawer/SideDrawer"
import Backdrop from "../Backdrop/Backdrop"

const Layout = ({ children, location }) => {
  const [showShadow, setShowShadow] = useState(false)
  const [show, setShow] = useState(false)

  const onOpen = () => setShow(true)

  const onClose = () => setShow(false)

  const countScroll = () => {
    const scrollPath = 90
    const scrollPosition = window.scrollY

    if (scrollPosition > scrollPath) setShowShadow(true)
    else setShowShadow(false)
  }

  useEffect(() => window.addEventListener("scroll", countScroll), [])

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", countScroll)
    }
  }, [])

  return (
    <>
      <GlobalStyle />
      <Navbar showShadow={showShadow} handleOpen={onOpen} />
      <Backdrop show={show} handleClose={onClose} />
      <SideDrawer show={show} handleClose={onClose} />
      <Transition location={location}>
        <Main show={show}>{children}</Main>
        <Fade>
          <Footer show={show} />
        </Fade>
      </Transition>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
